<template>
  <div class="u-placeholder absolute-align-center">
    <svg
      v-if="type==='messages'"
      width="70"
      height="70"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path d="M25.5215 51.3684V64.8959C25.5215 65.8409 26.1281 66.678 27.0265 66.9755C27.2511 67.0484 27.4815 67.0834 27.709 67.0834C28.3915 67.0834 29.0506 66.7626 29.4706 66.1909L37.3836 55.4226L25.5215 51.3684Z" />
        <path d="M69.0814 0.405431C68.4106 -0.0699857 67.5297 -0.134152 66.8006 0.247931L1.17555 34.5188C0.399713 34.9242 -0.0582036 35.7525 0.00596306 36.6246C0.0730464 37.4996 0.653463 38.2463 1.47888 38.5292L19.7226 44.765L58.5756 11.5442L28.5105 47.7663L59.086 58.2167C59.3135 58.2925 59.5526 58.3333 59.7918 58.3333C60.1885 58.3333 60.5822 58.2254 60.9293 58.0154C61.4835 57.6771 61.8597 57.1083 61.956 56.4696L69.9768 2.51126C70.0964 1.6946 69.7522 0.883764 69.0814 0.405431Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="70"
            height="70"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-else
      width="70"
      height="56"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M68.1641 2.33583C66.9403 1.11172 65.4687 0.5 63.7502 0.5H6.25015C4.53143 0.5 3.05981 1.11172 1.83583 2.33583C0.611716 3.56022 0 5.03115 0 6.75014V49.2505C0 50.9697 0.611716 52.44 1.83583 53.6649C3.05968 54.8887 4.5313 55.5002 6.25015 55.5002H63.7504C65.4688 55.5002 66.9403 54.8887 68.1643 53.6649C69.3887 52.44 70.0001 50.9689 70.0001 49.2505V6.75014C70 5.03115 69.3887 3.55967 68.1641 2.33583ZM64.9995 49.2495C64.9995 49.5881 64.876 49.881 64.6288 50.1284C64.3819 50.3761 64.0885 50.4986 63.7504 50.4986H6.25015C5.91166 50.4986 5.61873 50.3761 5.37136 50.1284C5.12386 49.881 5.00031 49.5881 5.00031 49.2495V16.7501C5.00031 16.4114 5.12386 16.1184 5.37136 15.8707C5.61887 15.6236 5.91166 15.5001 6.25015 15.5001H63.7504C64.0885 15.5001 64.3815 15.6232 64.6288 15.8707C64.876 16.1184 64.9995 16.4114 64.9995 16.7501V49.2495Z" />
      <path d="M13.7498 40.5005H11.2498C10.9113 40.5005 10.6184 40.6231 10.3711 40.8703C10.1238 41.1181 10 41.4112 10 41.7496V44.2494C10 44.5885 10.1238 44.881 10.3711 45.1288C10.6186 45.3764 10.9113 45.4991 11.2498 45.4991H13.7498C14.0881 45.4991 14.3812 45.3764 14.6286 45.1288C14.8758 44.881 14.9996 44.5885 14.9996 44.2494V41.7496C14.9996 41.4112 14.8758 41.1181 14.6286 40.8703C14.3812 40.624 14.0881 40.5005 13.7498 40.5005Z" />
      <path d="M13.7498 30.4995H11.2498C10.9113 30.4995 10.6184 30.624 10.3711 30.8708C10.1238 31.1186 10 31.4117 10 31.7498V34.2498C10 34.589 10.1238 34.8806 10.3711 35.1293C10.6186 35.3756 10.9113 35.4995 11.2498 35.4995H13.7498C14.0881 35.4995 14.3812 35.3756 14.6286 35.1293C14.8758 34.8807 14.9996 34.589 14.9996 34.2498V31.7498C14.9996 31.4117 14.8758 31.1177 14.6286 30.8708C14.3812 30.624 14.0881 30.4995 13.7498 30.4995Z" />
      <path d="M13.7498 20.5005H11.2498C10.9113 20.5005 10.6184 20.6239 10.3711 20.871C10.1238 21.1189 10 21.4113 10 21.7498V24.2503C10 24.5886 10.1238 24.8817 10.3711 25.1292C10.6186 25.3759 10.9113 25.5002 11.2498 25.5002H13.7498C14.0881 25.5002 14.3812 25.3764 14.6286 25.1292C14.8758 24.8817 14.9996 24.5886 14.9996 24.2503V21.7498C14.9996 21.4113 14.8758 21.1184 14.6286 20.871C14.3812 20.6242 14.0881 20.5005 13.7498 20.5005Z" />
      <path d="M58.7499 40.5005H21.2498C20.9115 40.5005 20.6184 40.6231 20.3711 40.8703C20.1238 41.1181 20 41.4112 20 41.7496V44.2494C20 44.5885 20.1238 44.881 20.3711 45.1288C20.6186 45.3764 20.9116 45.4991 21.2498 45.4991H58.7503C59.0895 45.4991 59.382 45.3764 59.6298 45.1288C59.8765 44.881 60.0002 44.5885 60.0002 44.2494V41.7496C60.0002 41.4112 59.8761 41.1181 59.6293 40.8703C59.382 40.6231 59.0888 40.5005 58.7499 40.5005Z" />
      <path d="M58.7499 30.4995H21.2498C20.9115 30.4995 20.6184 30.624 20.3711 30.8708C20.1238 31.1186 20 31.4117 20 31.7498V34.2498C20 34.589 20.1238 34.8806 20.3711 35.1293C20.6186 35.3756 20.9116 35.4995 21.2498 35.4995H58.7503C59.0895 35.4995 59.382 35.3756 59.6298 35.1293C59.8765 34.8807 60.0002 34.589 60.0002 34.2498V31.7498C60.0002 31.4117 59.8761 31.1177 59.6293 30.8708C59.382 30.624 59.0888 30.4995 58.7499 30.4995Z" />
      <path d="M58.7499 20.5005H21.2498C20.9115 20.5005 20.6184 20.6239 20.3711 20.871C20.1238 21.1189 20 21.4113 20 21.7498V24.2503C20 24.5886 20.1238 24.8822 20.3711 25.1292C20.6186 25.3759 20.9116 25.5002 21.2498 25.5002H58.7503C59.0895 25.5002 59.383 25.3764 59.6298 25.1292C59.8765 24.8817 60.0002 24.5886 60.0002 24.2503V21.7498C60.0002 21.4113 59.8761 21.1184 59.6293 20.871C59.382 20.6242 59.0888 20.5005 58.7499 20.5005Z" />
    </svg>
    <div class="u-placeholder__title">
      <slot>{{ $t('$app.no_data') }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UPlaceholder',
  props: {
    type: {
      type: String,
      default: 'list'
    }
  }
}
</script>
<style lang="scss">
.u-placeholder {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #919191;
  max-width: 25rem;

  &__title {
    margin-top: $spacer * 4;
    font-size: map-deep-get($headings, 'h4', 'size');
  }
}
</style>
